import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';

import img from 'img/blog/updates/filters_redesign.png';
import img2 from 'img/blog/updates/filters_redesign_2.png';

const Content = () => {
  return (
    <div>
      <p>
        Filters have got a new look and have been moved to the main window for better-using
        experience. To display filters&apos; list, click on the search bar and chose one of the
        filters.
      </p>
      <ImageModal src={img} alt="Filters redisgn" title="Display filters" />
      <p className="mt-5">
        The filters&apos; list is now displayed above the sessions list. It&apos;s easier to change
        it directly from the same view.
      </p>
      <ImageModal src={img2} loading="lazy" alt="Filters redisgn" title="Change filters" />
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Filters redesign',
  url: '/blog/filters-redesign/',
  description: 'Filters list has a new look',
  author,
  img,
  date: '2019-06-20',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
    img={frontmatter.img}
  />
);
